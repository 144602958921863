<template>
  <div>
    <!-- <module-list-add-new
      :is-add-new-module-sidebar-active.sync="isAddNewModuleSidebarActive"
      @refetch-data="refetchData"
    /> -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <div>
                <b-button
                  variant="primary"
                  @click="isAddNewModuleSidebarActive = true"
                >
                  <span class="text-nowrap">Add Module</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refModuleListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <div>
              <b-dropdown-item
                :to="{
                  name: 'apps-modules-edit',
                  params: { id: data.item._id },
                }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </div>

            <div>
              <b-dropdown-item @click="removeModule(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalModules"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
export default {
    data: () => ({
        perPage: 10,
        perPageOptions: [10, 25, 50, 100],
        currentPage: 1,
        searchQuery: '',
        isAddNewModuleSidebarActive: false,
        dataMeta: {
            from: 0,
            to: 0,
            of: 0,
        },
        totalModules: 0,
        isSortDirDesc: false,
        items: [],
        tableColumns: [
            {
                field: 'name',
                label: 'Name',
                sortable: true,
            },
            {
                field: 'description',
                label: 'Description',
                sortable: true,
            },
            {
                field: 'actions',
                label: 'Actions',
                sortable: false,
                width: '100px',
            },
        ],
    }),
    methods: {
        async removeModule(id) {
            console.log('removeModule', id);

            // const { data } = await this.$store.dispatch('apps/modules/remove', {
            //     id,
            // });

            // if (data.success) {
            //     this.$toast.open({
            //         message: 'Module removed successfully',
            //         type: 'is-success',
            //         position: 'is-top',
            //         duration: 5000,
            //     });
            //     this.refetchData();
            // } else {
            //     this.$toast.open({
            //         message: data.message,
            //         type: 'is-danger',
            //         position: 'is-top',
            //         duration: 5000,
            //     });
            // }
        },
    }
};
</script>

<style>
</style>